import * as React from 'react'
import Head from 'next/head'

import { spacing, Text } from '@thg-commerce/gravity-theme'
import { styled, mq } from '@thg-commerce/enterprise-theme'
import { i18n } from '@thg-commerce/enterprise-core'

export type StoryStreamProps = {
  storyStreamId: string
  integrityHash: string
  layoutClass: string
  storyStreamJavaScriptBaseUrl: string
  addStoryStreamTitle: boolean
}

const Title = styled.h1`
  ${Text('large1', 'alternate')};
  text-align: center;
  margin-bottom: ${spacing(3)};
`

const StyledStoryStreamContainer = styled.div`
  margin-bottom: ${spacing(7)};
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin-bottom: ${spacing(10)};
  }
`

const StyledStoryStream = styled.div`
  width: 100%;
`

export const StoryStream = ({
  storyStreamId,
  integrityHash,
  layoutClass,
  storyStreamJavaScriptBaseUrl,
  addStoryStreamTitle,
}: StoryStreamProps) => {
  const storyStreamSource = `${storyStreamJavaScriptBaseUrl}${storyStreamId}.js`

  const i18nText = {
    storyStreamTitle: i18n('product.storystream.title.text'),
  }
  return (
    <StyledStoryStreamContainer>
      {i18nText.storyStreamTitle && addStoryStreamTitle && (
        <Title>{i18nText.storyStreamTitle}</Title>
      )}
      <Head>
        <script
          type="text/javascript"
          crossOrigin="anonymous"
          src={storyStreamSource}
          integrity={integrityHash}
          async
        />
      </Head>
      <StyledStoryStream className={layoutClass} id="stry-wrapper" />
    </StyledStoryStreamContainer>
  )
}
